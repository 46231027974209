import axios from 'axios';
import { CustomRequest } from './common';
import {
    LoginReq,
    OtpPayload,
    ValidateOtp,
    WhatsappPreference,
} from '../modules/Investee/common/models/Login';
import { BankListReq } from '../modules/Investee/common/models/BankList';
import { SignupPayload } from '../modules/Investee/common/models/Signup';
import { CapitalDetailsReq } from '../modules/Investee/common/models/CapitalDetails';
import { downloadFile, monthYear } from '../modules/Investee/common/models/DownloadFile';
import {
    DeleteGlobalNeedInfoProps,
    GetGlobalNeedInfoProps,
    GlobalNeedInfoProps,
    TdsClaimsInterface,
} from '../modules/Investee/common/models/Tds';
import { DEAL_TYPES } from '../enums/appEnums';
import isNil from 'lodash/isNil';
import { generateRequestId } from '../util/utility';
import { debtPayload } from '../modules/Investee/pages/GetStartedPageNew/components/TotSelectionDrawer/TotSelectionDrawer';
import { RegisterClaimDataPayload, UpdateClaimPayload } from './Types/ApiContainerTypes';
import moment from 'moment';

/* // apply interceptor to global instance
interceptor will be called from NavigateFunctionComponent */

export const API_BASE_PATH = `${process.env.REACT_APP_API_URL}/api/`; // introduce a fallback here
// export const API_BASE_PATH = `http://localhost:8070/api/`;
export const CERBERUS_BASE_PATH = `${process.env.REACT_APP_CERBERUS_API_URL}/cerberus/`;
export const API_BASE_AUTH_PATH = `${API_BASE_PATH}auth/`;
export const UW_BASE_PATH = `${process.env.REACT_APP_UNDERWRITING_URL}/`;
// export const API_BASE_CERBERUS_PATH = `https://apidev4.recur.club/cerberus/rest/`;
export const API_BASE_CERBERUS_PATH = `${CERBERUS_BASE_PATH}rest/`;
export const WEBUTILS_URL = process.env.REACT_APP_WEBUTILS_API_URL;
export const MNEMOSYNE_URL = `${process.env.REACT_APP_MNEMOSYNE_API_URL}/`;
// export const MNEMOSYNE_URL = `http://localhost:8080/mnemosyne/`;

export const OLYMPUS_URL = `${process.env.REACT_APP_OLYMPUS_API_URL}/rest/`;
// export const OLYMPUS_URL = `http://localhost:8083/olympus/rest/`;
export const NOTIFICATION_URL = `${process.env.REACT_APP_NOTIFICATION_API_URL}/`;
export const BOOKKEEPER_URL = `${process.env.REACT_APP_BOOKKEEPER_API_URL}/`;
export const POLUS_URL = `${process.env.REACT_APP_POLUS_API_URL}/`;
const GET = 'GET';
const POST = 'POST';
const DELETE = 'DELETE';
const PUT = 'PUT';

const formattedUrl = ({ path, url }: { path?: string; url: string }) =>
    (path ?? API_BASE_PATH) + url;

export const postLoginInfo = (data: LoginReq): any => {
    const payload: CustomRequest = {
        method: 'POST',
        url: `${API_BASE_CERBERUS_PATH}access/login`,
        isAuthRequired: false,
        data,
    };
    return axios(payload);
};
export const postSignupInfo = (data: SignupPayload): any => {
    const payload: CustomRequest = {
        method: 'POST',
        url: `${API_BASE_CERBERUS_PATH}access/create/primary`,
        isAuthRequired: false,
        data,
    };
    return axios(payload);
};
export const sendOtp = (data: OtpPayload): any => {
    const payload: CustomRequest = {
        method: 'POST',
        url: `${API_BASE_CERBERUS_PATH}mfa/generateOtp`,
        isAuthRequired: false,
        data,
    };
    return axios(payload);
};
export const validateOtp = (data: ValidateOtp): any => {
    const payload: CustomRequest = {
        method: 'POST',
        url: `${API_BASE_CERBERUS_PATH}mfa/validateOtp`,
        isAuthRequired: false,
        data,
    };
    return axios(payload);
};

export const fetchOnboardingData = (investeeOnboardingId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${API_BASE_PATH}investee-onboarding/${investeeOnboardingId}`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchMonthlyViewData = (investeeOrganizationId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${UW_BASE_PATH}insights/data-vault/${investeeOrganizationId}/monthly-view`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchUserInfo = (userId: String): any => {
    const payload: CustomRequest = {
        method: GET,
        url: `${API_BASE_AUTH_PATH}profile/${userId}/user/info`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchBanks = (data: BankListReq, investeeOrgId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}repayments/organization/${investeeOrgId}/bank-list`,
        isAuthRequired: true,
        data,
    };
    return axios(payload);
};

export const saveCapitalDetailsOnStartApplication = (
    investeeOrgId: string,
    data: CapitalDetailsReq,
): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investee_organization_id/${investeeOrgId}/set-capitalRequired`,
        isAuthRequired: true,
        data,
    };
    return axios(payload);
};

export const fetchInvestorPortfolioGraphs = (
    dateRange: object,
    graphType: string,
    investorOrgId: string,
): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor/portfolio-overview`,
        isAuthRequired: true,
        data: {
            dateRange,
            graphType,
            investor_organization_id: investorOrgId,
        },
    };
    return axios(payload);
};

export const fetchFundingCalculatorValues = (investeeOnboardingId: string): any => {
    const payload: CustomRequest = {
        method: GET,
        url: `${API_BASE_PATH}investee_onboarding/${investeeOnboardingId}/get_funding_calculator_values`,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const downloadFileApi = (input: downloadFile): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}download-file`,
        isAuthRequired: true,
        data: input,
    };
    return axios(payload);
};

// axiosResponseType = "arraybuffer" | "blob" | "document" | "json" | "text" | "stream" (Axios responseType, takes one of these values)
export const fetchBase64UrlFromMultipartType = (
    url: string,
    axiosResponseType: string | any,
): any => {
    const payload: CustomRequest = {
        method: GET,
        url: url,
        isAuthRequired: false,
        responseType: axiosResponseType,
    };
    return axios(payload);
};
export const fetchDrawdownOrders = (input: object): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor/get-drawdowns`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchOffers = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}deals/investor_organization/get_offers`,
        data: {
            investor_organization_id: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchAllDeals = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}deals/investor-organization/get-all-deals`,
        data: {
            investor_organization_id: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchUnderwritingMapping = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investee-underwriter-mapping`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchInvestorOpsMapping = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investee-investor-ops-mapping`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const postAcceptTotAndESign = (investee_organization_id: string, stage_tot_id: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investee_onboarding/acceptTotAndESign`,
        data: { investee_organization_id, stage_tot_id },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const postResendSignReq = (investee_organization_id: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investee_onboarding/kyc_documents/resend-signature-request`,
        data: { investee_organization_id },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchTotData = (investeeOrganizationId: string, totId?: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}tot-history/get-last-accepted-sent-tot`,
        data: { investeeOrganizationId, totId },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchDebtTotData = (data: debtPayload) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}tot/get-debt-tot`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const acceptScaleTot = (stageTotId: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}debt-tot/accept`,
        data: {
            stageTotId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchTdsFilingReportDownload = (url: string, data: monthYear): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}${url}`,
        data: data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchInvestmentDetails = (
    investeeOrganizationId: string,
    investorOrganizationId: string,
): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor/company-progression/get-investment-details`,
        data: {
            investeeOrganizationId: investeeOrganizationId,
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchCompanyOverview = (investeeOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor/company-progression/get-company-overview`,
        data: {
            investeeOrganizationId: investeeOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchTotalAccepted = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/totalAccepted`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchTotalAcceptedForDebt = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/totalAcceptedForDebt`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchTotalSanctioned = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/totalSanctioned`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchTotalSanctionedForDebt = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/totalSanctionedForDebt`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchTotalDisbursed = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/totalDisbursed`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchMonthlyDisbursments = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/disbursedGraph`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchMonthlyDisbursmentsForDebt = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/disbursedGraphForDebt`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchFeeAndTenure = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/feeAndTenure`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchPrincipalOutstanding = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/principalOutstanding`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchAverageRecurGrade = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/recurGrade`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchCollectionDashboardData = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: GET,
        url: `${API_BASE_PATH}collections/companyDashboardCollectionSchedule?investeeOrgId=${investorOrganizationId}`,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchRepaymentHistoryGraph = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/repaymentHistory`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchDpdView = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor-investee/dpd/view`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchTotalAcceptedDrilldown = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/acceptedDealsDrilldown`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchTotalAcceptedDrilldownForDebt = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/acceptedDealsDrilldownForDebt`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchSanctionDrilldown = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/sanctionedDealsDrilldown`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchSanctionDrilldownForDebt = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/sanctionedDealsDrilldownForDebt`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchDisbursmentDrilldown = (investorOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor_organization/dashboard/disbursedDealsDrilldown`,
        data: {
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchInvesteeDpdData = (
    investeeOrganizationId: string,
    investorOrganizationId: string,
): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor-investee/dpd/view`,
        data: {
            investeeOrganizationId: investeeOrganizationId,
            investorOrganizationId: investorOrganizationId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchPGConnections = (companyId: string, requestId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${WEBUTILS_URL}/utilities/rest/pg/connection/all`,
        data: {
            companyId: companyId,
            requestId: requestId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const registerRazorPG = (input: object): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${WEBUTILS_URL}/utilities/rest/pg/register`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const whatsappPreference = (input: WhatsappPreference): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}notification/update-user`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const editRazorPG = (input: object): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${WEBUTILS_URL}/utilities/rest/pg/connection/edit`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchRcmData = (investeeOrganizationId: string): any => {
    const payload: CustomRequest = {
        method: GET,
        url: `${API_BASE_PATH}${investeeOrganizationId}/get-enabled-rcm`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchAllDealsLinkedToATot = (data: object): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}admin/deals/investee_organization/get_deals`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToEsignDocsAndMakeCompanyActive = (data: object): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investee_onboarding/kyc_documents/eSign_documents`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiForLegalitySign = (data: object): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}deals/aadhaar-sign`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToGetSidebarConfigTab = (data: Object): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}sidebar/company-tabs-config`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToGetDoesCompanyContainNotMetCompliance = (data: String): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}compliance/doesCompanyContainNotMetCompliance?investeeOrgId=${data}`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToGetWidgetData = (data: Object): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}sidebar/get-widget-data`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchSignedUrlToUpload = (input: object, orgId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${MNEMOSYNE_URL}file/signedUrl`,
        data: input,
        headers: {
            'organization-id': orgId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchKycDocuments = (input: object): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}deals/view-download-kyc-documents`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const saveMetaDataInDocService = (input: object, orgId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${MNEMOSYNE_URL}file/saveMetadata`,
        data: input,
        headers: {
            'organization-id': orgId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const isNameMatchWithPanFailed = (input: object): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investee-onboarding/pan-name-match`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const updateNameFetchedFromPan = (input: object): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investee-onboarding/pan-name-update`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const updateKycMetaData = (
    input: object,
    investeeOnboardingId: string,
    purpose: string,
    section: string,
): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investee-kyc-application/${investeeOnboardingId}/metadata-update?purpose=${purpose}&section=${section}`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const deleteDoc = (input: object, investeeOnboardingId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investee-kyc-application/${investeeOnboardingId}/delete-file`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchCompanyCollectionsHistory = (
    investeeOrgId: string,
    startDate?: string,
    endDate?: string,
): any => {
    const payload: CustomRequest = {
        method: GET,
        url:
            startDate && endDate
                ? `${OLYMPUS_URL}collection/companyCollections?investeeOrgId=${investeeOrgId}&startDate=${startDate}&endDate=${endDate}`
                : `${OLYMPUS_URL}collection/companyCollections?investeeOrgId=${investeeOrgId}`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const downloadFilePresigned = (docId: number): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${MNEMOSYNE_URL}file/downloadFilePresigned?fileId=${docId}`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const updateTallyConnectorStatus = (investeeOrganizationId: string, input: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investeeorganizations/${investeeOrganizationId}/data-update`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchCoborrowerDetails = (stageId: string, investeeOrgId?: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${API_BASE_PATH}tot-history/coborrower?${
            investeeOrgId ? `investeeOrganizationId=${investeeOrgId}` : `stageId=${stageId}`
        }`,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const callInviteCoborrower = (input: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investee/invite-coborrower`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const callUpdateCoborrowerDetails = (input: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}tot-history/coborrower`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchAddOnStatus = (investeeOrgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${API_BASE_PATH}investeeorganization/${investeeOrgId}/get-dashboard-add-on-status`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchMonthlyOnboardingStatus = (investeeOrgId: string, path: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${API_BASE_PATH}fetch-monthly-onboarding-status/${investeeOrgId}?path=${path}&isInvesteeOrgId=true`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fethcIntegrations = (investeeOnboardingId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${API_BASE_PATH}data-vault/${investeeOnboardingId}/investee-integration-info`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToSubmitAddOnLimitData = (investeeOnboardingId: string, path: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investeeOnboarding/${investeeOnboardingId}/metadata-update?stage=stage3&path=${path}`,
        data: {
            is_application_submitted: true,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchFinboxSessionUrl = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}finbox/get-session-url`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToUpdateMetaData = ({
    investeeOnboardingId,
    stage,
    subStage,
    path,
    data,
}: {
    investeeOnboardingId: string;
    stage: string;
    subStage: string;
    path: string | null | undefined;
    data: object;
}) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investeeOnboarding/${investeeOnboardingId}/metadata-update?stage=${stage}&substage=${subStage}&path=${path}`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToUpdateMetaDataV3 = ({
    investeeOnboardingId,
    stage,
    subStage,
    source,
    path,
    data,
}: {
    investeeOnboardingId: string;
    stage: string;
    subStage: string;
    source?: string;
    path: string | null | undefined;
    data: object;
}) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investeeOnboarding/${investeeOnboardingId}/v3/metadata-update?stage=${stage}&substage=${subStage}&source=${
            source ?? ''
        }&path=${path}`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchUploadSignedUrl = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investeeonboarding/s3/sign`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToDeleteFile = ({
    investeeOnboardingId,
    path,
    data,
}: {
    investeeOnboardingId: string;
    path: string;
    data: object;
}) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investee-onboarding/${investeeOnboardingId}/delete-file?path=${path}`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToFetchMonthlyViewData = (investeeOrgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${UW_BASE_PATH}insights/data-vault/${investeeOrgId}/monthly-view`,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchTdsClaimData = (input: TdsClaimsInterface) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}tds/investeeOrganization/getTdsClaimsData?investeeOrganizationId=${input?.investeeOrgId}&startDate=${input.startDate}&endDate=${input.endDate}&requestId=${input.requestId}
        `,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchTdsClaimDataAllTime = (input: TdsClaimsInterface) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}tds/investeeOrganization/getTdsClaimsData?investeeOrganizationId=${input?.investeeOrgId}&requestId=${input.requestId}&allTime=true`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const updateTdsClaimData = (input: TdsClaimsInterface) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}tds/investeeOrganization/updateTdsClaim`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const updateGlobalNeedInfo = (input: GlobalNeedInfoProps) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}onboarding/edit-global-need-info-req`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const getGlobalNeedInfo = (input: GetGlobalNeedInfoProps) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}onboarding/get-global-need-info-data`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const deleteGlobalNeedInfoDocs = (input: DeleteGlobalNeedInfoProps) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}onboarding/delete-global-need-info-docs        `,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const submitGlobalNeedInfo = (orgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}onboarding/submit-global-need-info-data`,
        params: {
            orgId: orgId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToIntegrateSource = ({
    source,
    data,
    path,
}: {
    source: string;
    data: object;
    path: string;
}) => {
    const ZOHO_INTEGRATION_URL = formattedUrl({ url: `integrations/zoho?path=${path}` });
    const payload: CustomRequest = {
        method: POST,
        url: ZOHO_INTEGRATION_URL,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToAcceptAddOnTot = ({ totId, data }: { totId: string; data: object }) => {
    const payload: CustomRequest = {
        method: POST,
        url: formattedUrl({ url: `tot-history/${totId}/acceptTot` }),
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchSecondaryRcmData = (orgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: formattedUrl({ url: `investee_organization/${orgId}/fetch_rcm_data` }),
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToFetchDeals = (
    investorOrgId: string,
    currentTab: string,
    companyName?: string,
) => {
    let url = `deal/${currentTab === DEAL_TYPES.EVALUATED_DEALS ? 'evaluated-deals' : 'new-deals'}`;
    const params = {
        investorOrganizationId: investorOrgId,
    };
    if (!isNil(companyName)) url += `?company_name=${companyName}`;
    const payload: CustomRequest = {
        method: GET,
        url: formattedUrl({ url, path: OLYMPUS_URL }),
        params: params,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToGetDocVerificationDeals = (
    pageTab: string,
    investorOrgId: string,
    companyName?: string,
) => {
    let url = `deal/doc-verification-deals`;
    const params = {
        docVerificationStatus: pageTab,
        investorOrganizationId: investorOrgId,
        ...(companyName && { companyName }),
    };
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}deal/doc-verification-deals`,
        params: params,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const callApiToGetPendingDrawdowns = (investorOrgId: string, companyName?: string) => {
    const data = {
        investorOrganizationId: investorOrgId,
        ...(companyName && { companyName }),
    };
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investor/open-drawdowns`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToFetchPortfolioDeals = (
    portfolioDealStatus: string,
    investorOrganizationId: string,
    companyName?: string,
) => {
    let url = `deal/portfolio-deals`;
    const params = {
        portfolioDealStatus: portfolioDealStatus,
        investorOrganizationId: investorOrganizationId,
        ...(companyName && { companyName }),
    };
    const payload: CustomRequest = {
        method: GET,
        url: formattedUrl({ url, path: OLYMPUS_URL }),
        params: params,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToUpdateInvestorDealStatus = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: formattedUrl({ url: 'deals/investor/updateOfferStatus' }),
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToUpdateDeal = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: formattedUrl({ url: 'deals/investee-organization/deals_update' }),
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const callApiToSaveContactUsData = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: formattedUrl({ url: 'app/contact-us' }),
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToSaveDealFeedback = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: formattedUrl({ url: 'deal/feedback', path: OLYMPUS_URL }),
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToFetchDealConversation = (dealOfferId?: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: formattedUrl({
            url: `conversation/${dealOfferId}/Investor/fetch-messages`,
            path: NOTIFICATION_URL,
        }),
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToSaveMessageOrDraftForDealConversation = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: formattedUrl({ url: 'conversation/send-message', path: NOTIFICATION_URL }),
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToDeleteFileFromDocService = (docId: number): any => {
    const payload: CustomRequest = {
        method: POST,
        url: formattedUrl({ url: 'file/deleteFiles', path: MNEMOSYNE_URL }),
        data: {
            identifierName: 'fileIds',
            docList: [docId],
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callAptToFetchCompanyMatchPercentage = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: formattedUrl({ url: 'deal/fetch-company-match-percentage', path: OLYMPUS_URL }),
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToFetchAllNach = (totId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}nach/all?totId=${totId}`,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const callApiToUpdateNachSignatory = (data: object) => {
    const payload: CustomRequest = {
        method: PUT,
        url: `${OLYMPUS_URL}nach/update-nach-signatory-details`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const callApiToSetupNach = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}nach/setup`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const callApiToSelectNachType = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}nach/select-nach-type`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchcibilVerificationStatus = (data: {
    investeeOrgId: String;
    fetchDetail?: boolean;
}) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}cibil/getVerificationStatus`,
        params: {
            investeeOrganizationId: data?.investeeOrgId,
            fetchDetail: data?.fetchDetail,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const verifyGstin = (payload: object) => {
    const payloadData: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}validate-gstin`,
        data: payload,
        isAuthRequired: true,
    };
    return axios(payloadData);
};

export const sendCibilVerificationStatus = (data: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}cibil/captureConsent`,
        data: {
            encryptedKey: data,
        },
        isAuthRequired: false,
    };
    return axios(payload);
};

export const sendCibilVerificationRequest = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}cibil/sendConsentLink`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const downloadFileBulk = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${MNEMOSYNE_URL}file/downloadFile/bulk`,
        data,
        responseType: 'blob',
        isAuthRequired: true,
    };
    return axios(payload);
};

export const FetchWaitlistedData = (data: {
    identifierId: string | number;
    requestId: string | number;
    orgId: string;
}) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}deal-report/data-get`,
        params: {
            identifierId: data.identifierId,
            requestId: data.requestId,
            orgId: data.orgId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToUploadPhysicalNach = (data: {
    mandateId: string | number;
    uploadedDocId: string | number;
    uploadedDocName: string;
}) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${BOOKKEEPER_URL}nach/public/upload-physical-mandate`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToUpdateIndicativeTerms = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}deal/save-indicative-terms`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const callApiToUpdateLimitOverview = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: formattedUrl({ url: 'deals/investee_organization/limit_overview_details' }),
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callAptToFetchCapitalAvailableData = (investeeOrgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: formattedUrl({
            url: `dashboard/${investeeOrgId}/getCapitalAvailableData`,
            path: OLYMPUS_URL,
        }),
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callAptToFetchCollectionMode = (investeeOrgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: formattedUrl({
            url: `dashboard/getCollectionMode?investeeOrganizationId=${investeeOrgId}`,
            path: OLYMPUS_URL,
        }),
        isAuthRequired: true,
    };
    return axios(payload);
};

/**
 * api to update odfd data present in Olympus. Endpoint: /saveODFDData
 * @param data
 * @returns
 */
export const callApiToUpdateODFDdata = (data: object, path: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}onboarding/saveODFDData?path=${path}`,
        data,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToUpdateDocumentationStatus = (data: {
    reviewedBy: string | null;
    investorDealId: string | null;
    documentationStatus: string | null;
}) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}deal/updateDocumentationStatus`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callAptToFetchRepaymentSchedule = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: formattedUrl({ url: 'tot-history/get-repayment-schedule' }),
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToFetchForecastData = (investeeOrgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: formattedUrl({
            url: 'insights/forecast/fetchForecast',
            path: UW_BASE_PATH,
        }),
        params: {
            investeeOrgId,
            isCapitalForecastForDashboard: true,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchTdsClaimsForInvestor = (params: object) => {
    const reqObj: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}tds/investor/getTdsClaimsData`,
        params,
        isAuthRequired: true,
    };
    return axios(reqObj);
};

export const postTdsClaimForInvestor = (payload: object) => {
    const reqObj: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}tds/investor/updateTdsClaim`,
        data: payload,
        isAuthRequired: true,
    };
    return axios(reqObj);
};

export const getCounterSignLinkFromEncryptedDealId = (payload: { encryptedDealId: string }) => {
    const reqObj: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}deal/getCounterSignLinkFromEncryptedDealId`,
        data: payload,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(reqObj);
};

export const regenerateCounterSigningLink = (payload: { dealId: string }) => {
    const reqObj: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}deal/generateCountersigningLink`,
        params: {
            dealId: payload.dealId,
        },
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(reqObj);
};

export const callApiToUpdateActiveLoanOrDebt = (data: any) => {
    const urlEndpoint = `${API_BASE_PATH}investeeOnboarding/${data.investeeOnboardingId}/metadata-update?stage=stage2&substage=debtSchedule&path=${data.path}`;
    const payload: CustomRequest = {
        method: POST,
        url: urlEndpoint,
        data: data.value,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callApiToUpdateLoanCheckInNeedInfoOnboarding = (data: any) => {
    const urlEndpoint = `${API_BASE_PATH}investeeOnboarding/${data.investeeOnboardingId}/v3/metadata-update?stage=stage2&substage=debtSchedule&path=${data.path}`;
    const payload: CustomRequest = {
        method: POST,
        url: urlEndpoint,
        data: data.value,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const callApiToGetCounterSignUser = (investorOrgId: String, requestId: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}deal/getCounterSignUser`,
        data: {
            investorOrgId: investorOrgId,
            requestId: requestId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const updatePecDetails = (data: any) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}autoinvest/create-and-update-pec`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchNachStatusOldLines = (orgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}nach/all-tot-nach-status`,
        params: {
            orgId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const getDealManagerData = (investorOrganizationId: string, requestId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}deal/investee-deal-manager-mapping`,
        data: {
            investorOrganizationId: investorOrganizationId,
            requestId: requestId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchRecurScaleOffers = () => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}recur-products/scale-offers`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchKycAppData = (investeeOnboardingId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${API_BASE_PATH}investee_kyc_application/investee_onboarding/${investeeOnboardingId}/investee/documents`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchNoOfChartsUnlocked = (investeeOrgId: string) => {
    const reqObj: CustomRequest = {
        method: GET,
        url: `${UW_BASE_PATH}insights/dashboard/${investeeOrgId}/noOfChartsLocked`,
        isAuthRequired: true,
    };
    return axios(reqObj);
};

export const fetchARRGrowthPercentile = (investeeOrgId: string) => {
    const reqObj: CustomRequest = {
        method: GET,
        url: `${UW_BASE_PATH}benchmark/arrGrowth?investeeOrgId=${investeeOrgId}&criterion=Against_Similar_Sector`,
        isAuthRequired: true,
    };
    return axios(reqObj);
};

export const fetchInsightsGrowth = (startingDate: any, endingDate: any) => {
    const reqObj: CustomRequest = {
        method: GET,
        url: `${POLUS_URL}rest/insights/tab/data?requestId=${generateRequestId()}&tabName=revenue&startDate=${startingDate}&endDate=${endingDate}&tenure=Monthly`,
        isAuthRequired: true,
    };
    return axios(reqObj);
};

export const editIpaDocInDealOffer = (input: object, orgId: string, operationType: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}deals/admin/${operationType}/offer_update`,
        data: input,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchCsvForZeusUser = (input: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}onboarding/downloadPaymentSchedule`,
        data: input,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
        responseType: 'blob',
    };
    return axios(payload);
};

export const fetchCsvForTdsDeposit = (input: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}tds/download-tds-deposit-excel`,
        data: input,
        isAuthRequired: true,
        responseType: 'blob',
    };
    return axios(payload);
};

export const callRegisterClaim = (data: RegisterClaimDataPayload) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}tds/register-claim`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchTdsClaim = (customerQueryId: string | null, requestBy?: string | null) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}tds/get-claim`,
        params: {
            customerQueryId: customerQueryId,
            requestBy: requestBy,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchTdsDepositdates = (investeeOrgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}tds/get-tds-deposit-dates`,
        params: {
            investeeOrganizationId: investeeOrgId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchInvestorInfo = (customerQueryId: string | null) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}tds/get-investor-info`,
        params: {
            customerQueryId: customerQueryId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const callUpdateClaim = (data: UpdateClaimPayload) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}tds/update-claim`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchDscStatus = (fileId: string | number) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${MNEMOSYNE_URL}file/getDscStatus`,
        params: {
            fileId: Number(fileId),
            requestId: generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const getCustomerQueries = (
    investeeOrganizationId: string,
    isInvestor: boolean,
    ticketType?: string,
    requestBy?: string,
) => {
    let params: any = {};
    if (isInvestor) params['investorOrganizationId'] = investeeOrganizationId;
    else params['investeeOrganizationId'] = investeeOrganizationId;
    if (ticketType) params['requestType'] = ticketType;
    if (requestBy) params['requestBy'] = requestBy;
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}customerSupport/get-support-queries`,
        data: params,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const getScheduledInvestorLedger = (input: any, responseType?: any) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}paymentledger/fetchpaymentledger`,
        data: input,
        headers: {
            'request-id': generateRequestId(),
        },
        responseType: responseType ?? 'json',
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchDrawdowns = (input: any) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}documentQuery/fetch-drawdowns`,
        data: input,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const getAllTdsPendingForOrders = (input: any) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}documentQuery/tds-pending-orders`,
        data: input,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const getCpForTotId = (totId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}cp/getAllCp?totId=${totId}`,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const updateCpDataForCompany = (data: any) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}cp/submit`,
        headers: {
            'request-id': generateRequestId(),
        },
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const registerBalanceConfirmation = (input: any) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}documentQuery/register`,
        data: input,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const registerSupportQueryTicket = (input: any) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}customerSupport/register`,
        data: input,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchBalanceConfirmationData = (customerQueryId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}documentQuery/get-data`,
        params: {
            customerQueryId: customerQueryId,
        },
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const getSupportQueryApi = (customerQueryId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}customerSupport/get-data`,
        params: {
            customerQueryId: customerQueryId,
        },
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const updateBalanceConfirmationApi = (input: any) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}documentQuery/update`,
        data: input,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const fetchApplicationStrength = (
    orgId: string,
    identifierId: string,
    identifierType: string,
) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}applicationStrength/getStrength`,
        data: {
            orgId: orgId,
            identifierId: identifierId,
            identifierType: identifierType,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callPartiallySubmitApplication = (
    orgId: string,
    identifierId: string,
    identifierType: string,
) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}applicationStrength/partiallySubmit`,
        data: {
            orgId: orgId,
            identifierId: identifierId,
            identifierType: identifierType,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchInvestorWiseCollectionData = (investeeOrgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}collection/getCollectionViewInvestorWise`,
        params: {
            investeeOrganizationId: investeeOrgId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const callParseDocument = ({
    docId,
    extractionConfigId,
}: {
    docId: string;
    extractionConfigId: string;
}) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}befe/parseDocument`,
        data: {
            docId,
            extractionConfigId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchParsedData = (textractReferenceId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}payment/getParsedData`,
        params: {
            textractReferenceId: textractReferenceId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};
export const submitTotalRepaymentProof = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}payment/recordPayment`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchRecordedPaymentAmount = (orgId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${OLYMPUS_URL}payment/getRecordedPaymentAmount`,
        params: {
            investeeOrganizationId: orgId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchMobileUploadingSteps = (investeeOrganizationId: string) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${API_BASE_PATH}investee-mobile-uploading-status/${investeeOrganizationId}`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const sentNotificationViaMailForMobile = (investeeOnboardingId: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}onboarding/send-mail-via-mobile`,
        params: {
            investeeOnboardingId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const reapplyForOnboarding = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}onboarding/reapply-waitlisted-onboarding`,
        data,
        isAuthRequired: true,
    };
    return axios(payload);
};
export const getRecordedPayments = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}payment/getRecordedPayments`,
        data: data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const updateRecordPaymentStatus = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}payment/markRecordedPaymentStatus`,
        data: data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fundingCalculation = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}recur-products/fundingCalculator`,
        data: data,
    };
    return axios(payload);
};

export const updateGSTAddress = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}investee_kyc_application/change-gst-address`,
        data: data,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchConfigForAdditionalDocs = (data: { configName: string }) => {
    const payload: CustomRequest = {
        method: GET,
        url: `${POLUS_URL}rest/unifier/fetchConfigPolus`,
        params: {
            requestId: generateRequestId(),
            name: data.configName,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export function getCompaniesLegalNamesListService(payload: any) {
    return axios({
        url: 'https://api.recur.club/tools/rest/search/autocomplete/companies/name',
        method: GET,
        params: {
            requestId: moment().format('YYYYMMDDHH:mm:ss'),
            queryString: payload?.queryString,
        },
    });
}
export const fetchDealManager = (offerId: string) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}deal/fetchDealManager`,
        data: { offerId },
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const addDealManager = (data: object) => {
    const payload: CustomRequest = {
        method: POST,
        url: `${OLYMPUS_URL}deal/addDealManager`,
        data: data,
        headers: {
            'request-id': generateRequestId(),
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const fetchLenderPlatfromFeePercent = (investorDealId: string): any => {
    const payload: CustomRequest = {
        method: POST,
        url: `${API_BASE_PATH}deals/fetchDealDetails`,
        data: {
            investorDealId: investorDealId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export default axios;
