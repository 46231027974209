import {
    API_STATUS,
    AUTO_WITHDRAW_COVER_MEHTODS,
    BASE_TOT,
    BASE_TOT_STATUS,
    COLLECTION_METHODS,
    LAKH,
    SHOW_NOTIFICATION_STATUS,
    TOT_STATUS,
    VALID_PAYMENT_MODES,
} from '../../../enums/appEnums';
import moment from 'moment/moment';
import callApi from '../../../util/apiCaller';
import { showNotification } from '../../../util/utility';
import { fetchKycAppData } from '../../../common/ApiContainer';
import { isEmpty } from 'lodash';

const {
    NACH_WEEKLY,
    NACH_FORTNIGHTLY,
    RAZORPAY_SPLIT,
    CASHFREE_SPLIT,
    DIRECT_COLLECTION,
    AMAZON,
    SWIGGY,
    ZOMATO,
    SHIPROCKET,
    FLIPKART,
    EASEBUZZ,
    NYKAA,
    SNAPDEAL,
    PAYTM,
} = COLLECTION_METHODS;

export const PAYMENT_MODES: {
    [key: string]: {
        label: string;
        learnMore: string;
        subText: string;
    };
} = {
    [NACH_WEEKLY]: {
        label: 'NACH AUTO-DEBIT (Weekly)',
        learnMore:
            "Any shortfall in weekly debit will be collected as part of the following week's collection.#Any shortfall in total collections for the month will be collected using NACH Auto Debit by the due date",
        subText: 'auto collected using NACH on *rcm_dates of every month.',
    },
    [NACH_FORTNIGHTLY]: {
        label: 'NACH AUTO-DEBIT (Fortnightly)',
        learnMore:
            "Any shortfall in fortnightly debit will be collected as part of the following week's collection.#Any shortfall in total collections for the month will be collected using NACH Auto Debit by the due date",
        subText:
            'repayment to be collected using NACH Auto-Debit on 11th of every month and remaining 50% repayment for the month to be collected using NACH Auto-Debit by the repayment due date.',
    },
    [RAZORPAY_SPLIT]: {
        label: 'RAZORPAY SPLIT (Daily)',
        learnMore:
            "Any shortfall in daily debit will be collected as part of the following day's collection.#Any shortfall in total collections for the month will be collected using NACH Auto Debit by the due date",
        subText:
            'repayment to be collected daily using Razorpay Split from *start_date to *end_date of every month and any remaining repayment for the month to be collected using NACH Auto-Debit by the repayment due date.',
    },
    [CASHFREE_SPLIT]: {
        label: 'CASHFREE SPLIT',
        learnMore:
            'Any shortfall in daily debit will be collected on the following day.#Any shortfall in total collections for the month will be collected using NACH Auto Debit by the due date',
        subText:
            'collected daily from *start_date to *end_date of every month from your Cashfree account. ',
    },
    [DIRECT_COLLECTION]: {
        label: 'DIGITAL ACCOUNT COLLECTION',
        learnMore:
            'Any excess amount collected from an eligible customer will be auto transferred to your selected bank account.#Any shortfall in total collections for the month will be collected using NACH Auto Debit by the due date',
        subText: 'to be collected directly into your digital account',
    },
    [AMAZON]: {
        label: 'AMAZON',
        learnMore:
            'Any excess amount collected from an eligible customer will be auto transferred to your selected bank account.#Any shortfall in total collections for the month will be collected using NACH Auto Debit by the due date',
        subText:
            'repayment to be collected using Amazon and any remaining repayment for the month to be collected using NACH Auto-Debit by the repayment due date.',
    },
    [SWIGGY]: {
        label: 'SWIGGY',
        learnMore:
            'Any excess amount collected from an eligible customer will be auto transferred to your selected bank account.#Any shortfall in total collections for the month will be collected using NACH Auto Debit by the due date',
        subText:
            'repayment to be collected using Swiggy and any remaining repayment for the month to be collected using NACH Auto-Debit by the repayment due date.',
    },
    [ZOMATO]: {
        label: 'ZOMATO',
        learnMore:
            'Any excess amount collected from an eligible customer will be auto transferred to your selected bank account.#Any shortfall in total collections for the month will be collected using NACH Auto Debit by the due date',
        subText:
            'repayment to be collected using Zomato and any remaining repayment for the month to be collected using NACH Auto-Debit by the repayment due date.',
    },
    [SHIPROCKET]: {
        label: 'SHIPROCKET',
        learnMore:
            'Any excess amount collected from an eligible customer will be auto transferred to your selected bank account.#Any shortfall in total collections for the month will be collected using NACH Auto Debit by the due date',
        subText:
            'repayment to be collected using Shiprocket and any remaining repayment for the month to be collected using NACH Auto-Debit by the repayment due date.',
    },
    [FLIPKART]: {
        label: 'FLIPKART',
        learnMore:
            'Any excess amount collected from an eligible customer will be auto transferred to your selected bank account.#Any shortfall in total collections for the month will be collected using NACH Auto Debit by the due date',
        subText:
            'repayment to be collected using Flipkart and any remaining repayment for the month to be collected using NACH Auto-Debit by the repayment due date.',
    },
    [EASEBUZZ]: {
        label: 'EASEBUZZ',
        learnMore:
            'Any excess amount collected from an eligible customer will be auto transferred to your selected bank account.#Any shortfall in total collections for the month will be collected using NACH Auto Debit by the due date',
        subText:
            'repayment to be collected using Easebuzz and any remaining repayment for the month to be collected using NACH Auto-Debit by the repayment due date.',
    },
    [NYKAA]: {
        label: 'NYKAA',
        learnMore:
            'Any excess amount collected from an eligible customer will be auto transferred to your selected bank account.#Any shortfall in total collections for the month will be collected using NACH Auto Debit by the due date',
        subText:
            'repayment to be collected using Nykaa and any remaining repayment for the month to be collected using NACH Auto-Debit by the repayment due date.',
    },
    [SNAPDEAL]: {
        label: 'SNAPDEAL',
        learnMore:
            'Any excess amount collected from an eligible customer will be auto transferred to your selected bank account.#Any shortfall in total collections for the month will be collected using NACH Auto Debit by the due date',
        subText:
            'repayment to be collected using Snapdeal and any remaining repayment for the month to be collected using NACH Auto-Debit by the repayment due date.',
    },
    [PAYTM]: {
        label: 'PAYTM',
        learnMore:
            'Any excess amount collected from an eligible customer will be auto transferred to your selected bank account.#Any shortfall in total collections for the month will be collected using NACH Auto Debit by the due date',
        subText:
            'repayment to be collected using Paytm and any remaining repayment for the month to be collected using NACH Auto-Debit by the repayment due date.',
    },
};

export const getPaymentModeSubText = ({
    source,
    totalPayments,
    data,
    perMonthAmt,
}: {
    source: string;
    totalPayments: number | null;
    data: any;
    perMonthAmt?: number | null;
}) => {
    const getAmount = (
        source: string,
        totalPayments: number | null,
        data: any,
        perMonthAmt?: number | null,
    ) => {
        const paymentObj = data?.secondary_rcm?.[source];
        const sourceAmount = paymentObj?.amount;
        if (sourceAmount) return sourceAmount;
        const perMonthAmount =
            perMonthAmt ??
            (totalPayments &&
                data?.custom_tenure_limit_max !== '-' &&
                (totalPayments / data?.custom_tenure_limit_max) * LAKH);
        if (perMonthAmount) {
            let amountToShow;
            if (source === NACH_WEEKLY) amountToShow = 25;
            if (source === NACH_FORTNIGHTLY) amountToShow = 50;
            if (source === RAZORPAY_SPLIT || source === CASHFREE_SPLIT)
                amountToShow = 100 / (paymentObj?.end_date - paymentObj?.start_date + 1);
            if (AUTO_WITHDRAW_COVER_MEHTODS.includes(source) && source != CASHFREE_SPLIT) {
                amountToShow = 100;
            }
            return amountToShow?.toFixed(2);
        }
        return '-';
    };

    const START_DATE = 'start_date';
    const END_DATE = 'end_date';
    const RCM_DATES = '*rcm_dates';
    let subText = PAYMENT_MODES[source]?.subText;
    if (subText?.includes(RCM_DATES))
        subText = subText?.replace(RCM_DATES, data?.secondary_rcm?.[source]['text'] ?? '');
    if (subText?.includes(START_DATE))
        subText = subText?.replace('*' + START_DATE, data?.secondary_rcm?.[source]?.[START_DATE]);
    if (subText?.includes(END_DATE))
        subText = subText?.replace('*' + END_DATE, data?.secondary_rcm?.[source]?.[END_DATE]);
    if (source === DIRECT_COLLECTION) {
        return 'INR ' + getAmount(source, totalPayments, data, perMonthAmt) + ' ' + subText + ' ';
    }
    return getAmount(source, totalPayments, data, perMonthAmt) + '% ' + subText + ' ';
};

export function ordinalsuffix(value: number) {
    const j = value % 10;
    const k = value % 100;
    if (j === 1 && k !== 11) {
        return value + 'st';
    }
    if (j === 2 && k !== 12) {
        return value + 'nd';
    }
    if (j === 3 && k !== 13) {
        return value + 'rd';
    }
    return value + 'th';
}

export function addWorkingDays(inputMoment: moment.Moment, numDays: number = 10): moment.Moment {
    let result = inputMoment.clone();
    let workingDaysAdded = 0;

    while (workingDaysAdded < numDays) {
        result.add(1, 'days');
        const dayOfWeek = result.day();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
            workingDaysAdded++;
        }
    }

    return result;
}

export const fetchUpcomingPaymentDetails = async (investeeOrganizationId: string) => {
    try {
        const res = await callApi('investeeorganization/upcoming-payment', 'post', {
            investee_organization_id: investeeOrganizationId,
        });
        if (res && res.status === API_STATUS.SUCCESS) {
            return res.data;
        } else {
            const message = res.data?.message ?? 'Some error occurred';
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return null;
        }
    } catch (error) {
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Some error occurred');
        return null;
    }
};

export const showAuthSign = (isSubmission: boolean, totShared: boolean, isAuthSign: boolean) => {
    return (isSubmission || totShared) && isAuthSign;
};

export async function isAuthSign(onboardingId: any) {
    const response = await fetchKycAppData(onboardingId);
    const signComplete = !response?.data?.data?.is_authorized_signatory_complete;
    return signComplete;
}

export function totShared(totData: any) {
    const tempIsTotSent =
        totData[BASE_TOT]?.[TOT_STATUS] === BASE_TOT_STATUS.SENT ||
        totData[BASE_TOT]?.[TOT_STATUS] === BASE_TOT_STATUS.UPDATED_NOT_SENT;
    return tempIsTotSent;
}

export const checkIfValidPaymentModeIsPresent = (paymentModes: any) => {
    if (isEmpty(paymentModes)) return false;
    for (let mode of paymentModes) {
        if (VALID_PAYMENT_MODES.includes(mode)) return true;
    }
    return false;
};
